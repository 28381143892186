import buildQuery from 'odata-query';
import apiService from '@/core/common/services/api.service';
import odataErrorHandler from '@/core/common/services/error-handler.service';
import { groupBy } from '@/core/common/helpers/utils';

export async function getDomTeamDashboardMetadata(reisetermine) {
  const reiseterminIds = reisetermine.map(reisetermin => reisetermin.id);
  return apiService
    .post('Reisetermin/dom-metadata', { reiseterminIds: reiseterminIds })
    .then(res => {
      if (!res.data || !res.data.result || !res.data.result) {
        return null;
      }
      return res.data.result;
    })
    .catch(error => {
      console.error(error);
    });
}

export async function getReisetermin() {
  let query = buildQuery(...arguments);
  if (arguments[0].hasOwnProperty('compute')) {
    query = query.slice(0, 1) + arguments[0].compute + '&' + query.slice(1);
  }
  console.log('query: ', query);

  return apiService
    .get('Reisetermin' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getFlugverfuegbarkeit() {
  let query = buildQuery(...arguments);
  if (arguments[0].hasOwnProperty('compute')) {
    query = query.slice(0, 1) + arguments[0].compute + '&' + query.slice(1);
  }
  console.log('query: ', query);
  return apiService
    .get('Flugverfuegbarkeit' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getReise() {
  let query = buildQuery(...arguments);
  return apiService
    .get('Reise' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getKunde() {
  let query = buildQuery(...arguments);
  return apiService
    .get('Kunde' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getKundeDuplicate() {
  let query = buildQuery(...arguments);
  return apiService
    .get('KundeDuplicate' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getKundeMaster() {
  let query = buildQuery(...arguments);
  return apiService
    .get('KundeMaster' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getAgenturDuplicates() {
  let query = buildQuery(...arguments);
  return apiService
    .get('AgenturDuplicateSuggestion' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}
export async function getAgenturMaster() {
  let query = buildQuery(...arguments);
  return apiService
    .get('AgenturMaster' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getAllCountriesGroupedByRegions() {
  //Gets all aviable regions and countries and groups them by region
  //Even regions and countries without any reisetermine
  return getReise({
    transform: [
      {
        groupBy: { properties: ['Region', 'Country'] },
      },
    ],
  }).then(response => {
    return groupBy(response.data, 'region');
  });
}

export async function getVorgang() {
  let query = buildQuery(...arguments);
  return apiService
    .get('Vorgang' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getAuditLog() {
  let query = buildQuery(...arguments);
  return apiService
    .get('AuditLogEntry' + query)
    .then(res => {
      return res.data && res.data.result ? transformOdataObject(res.data.result) : [];
    })
    .catch(odataErrorHandler);
}

export async function getUser() {
  let query = buildQuery(...arguments);
  return new Promise((resolve, reject) => {
    return apiService
      .get('User' + query)
      .then(res => {
        resolve(res.data && res.data.result ? transformOdataObject(res.data.result) : []);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getKundenIdFilterOptions(id = 1) {
  return new Promise((resolve, reject) => {
    return apiService
      .get(`Kunde?$expand=Name&$filter=startswith(cast(id,Edm.String),cast(${id},Edm.String))`)
      .then(res => {
        const kunden = [...res.data.result.value] || [];
        let idNameKunden = kunden.map(kunde => {
          const name = `${(kunde.name || '') && (kunde.name.vorname || '') && kunde.name.vorname} ${
            (kunde.name || '') && (kunde.name.nachname || '') && kunde.name.nachname
          }`;
          return {
            name: name,
            id: kunde.id,
            label: `${name} (${kunde.id})`,
          };
        });
        resolve(idNameKunden);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getKundenMasterIdFilterOptions(id = 1) {
  return new Promise((resolve, reject) => {
    return apiService
      .get(`Kunde?$expand=Name&$filter=startswith(cast(id,Edm.String),cast(${id},Edm.String))`)
      .then(res => {
        const kunden = [...res.data.result.value] || [];
        let idNameKunden = kunden.map(kunde => {
          const name = `${(kunde.name || '') && (kunde.name.vorname || '') && kunde.name.vorname} ${
            (kunde.name || '') && (kunde.name.nachname || '') && kunde.name.nachname
          }`;
          return {
            name: name,
            id: kunde.id,
            label: `${name} (${kunde.id})`,
          };
        });
        resolve(idNameKunden);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getAgenturIdFilterOptions(id = 1) {
  return new Promise((resolve, reject) => {
    return apiService
      .get(`Agentur?$filter=startswith(cast(id,Edm.String),cast(${id},Edm.String))`)
      .then(res => {
        const agenturen = [...res.data.result.value] || [];
        let idNameAgenturen = agenturen.map(agentur => {
          return {
            name: agentur.name,
            id: agentur.id,
            label: `${agentur.name || 'Kein Name'} (${agentur.id})`,
          };
        });
        resolve(idNameAgenturen);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getAgenturMasterIdFilterOptions(id = 1) {
  return new Promise((resolve, reject) => {
    return apiService
      .get(`AgenturMaster?$filter=startswith(cast(id,Edm.String),cast(${id},Edm.String))`)
      .then(res => {
        const agenturen = [...res.data.result.value] || [];
        let idNameAgenturen = agenturen.map(agentur => {
          return {
            name: agentur.name,
            id: agentur.id,
            label: `${agentur.name || 'Kein Name'} (${agentur.id})`,
          };
        });
        resolve(idNameAgenturen);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getReiseterminKuerzelFilterOptions(kuerzel) {
  return new Promise((resolve, reject) => {
    return apiService
      .get(`Reisetermin?$filter=startswith(reiseterminkuerzel,'${kuerzel}')&$select=reiseterminkuerzel,id`)
      .then(res => {
        const reisetermine = [...res.data.result.value] || [];
        let idReisetermine = reisetermine.map(reisetermin => {
          return {
            reiseterminkuerzel: reisetermin.reiseterminkuerzel,
            id: reisetermin.id,
          };
        });
        resolve(idReisetermine);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getReiseFilterOptions(filter) {
  const reisenMitReisetermineInFuture = {
    reisetermine: {
      any: { startdatum: { ge: new Date() } },
    },
  };
  return new Promise((resolve, reject) => {
    let query = buildQuery({
      select: ['reisekuerzel', 'id'],
      filter: {
        and: [filter, reisenMitReisetermineInFuture],
      },
      //   reisekuerzel: { startswith: 'A' },
    });
    return apiService
      .get('Reise' + query)
      .then(res => {
        resolve(res?.data?.result?.value);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function transformOdataObject(obj) {
  return Object.assign({}, { count: obj['@odata.count'] }, { data: obj.value || obj });
}
