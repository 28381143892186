import { getReiseFilterOptions } from '@/core/common/services/odata.service';
import { isObjectJSONStringifyEqual, getRegionsmanagerByShortname } from '@/core/common/helpers/utils';
import { convertTableSortToOdata } from '@/core/common/helpers/odataUtils';
import {
  buildQueryElementFromArrayWithProperty,
  buildDateRangeFilterQuery,
} from '@/core/common/helpers/filterUtils.js';

import apiService from '@/core/common/services/api.service';
import * as odataService from '@/core/common/services/odata.service';
import { format } from 'date-fns';

export const GET_REISE_DETAIL = 'reisen.getReiseDetail';
export const SET_REISE_DETAIL = 'reisen.setReiseDetail';

export const SAVED_FILTER = 'reisen.savedFilter';
export const GET_REISEN = 'reisen.getReisen';
export const SET_REISEN = 'reisen.setReisen';

export const UPDATE_REISE = 'reisen.updateReise';

export const SET_IS_ADDING_REISE = 'reisen.setIsAddingReise';
export const ADD_REISE = 'reisen.addReise';

export const REISEN_SORT_CHANGED = 'reisen.sortChanged';
export const REISEN_PAGE_CHANGED = 'reisen.pageChanged';

export const SET_STRINGIFIED_FILTER = 'reisen.setStringifiedFilter';

export const SET_REISEN_COUNT = 'reisen.setReisenCount';
export const SET_REISEN_TOP = 'reisen.setReisenTop';
export const SET_REISEN_SKIP = 'reisen.setReisenSkip';
export const SET_REISEN_ORDER_BY = 'reisen.setReisenOrderBy';
export const SET_REISEN_IS_BUSY = 'reisen.setReisenIsBusy';
export const SET_IS_INITIALLY_LOADING = 'reisen.setIsInitiallyLoading';

export const GET_ALL_COUNTRIES_GROUPED_BY_REGIONS = 'reisen.getCountriesGroupedByRegion';
export const SET_ALL_COUNTRIES_GROUPED_BY_REGIONS = 'reisen.getCountriesGroupedByRegion';

export const SET_ALL_FILTER_TO_INTIAL = 'reisen.setAllFilterToInital';
export const RESET_FILTER = 'reisen.resetFilter';
export const SET_IS_ANY_FILTER_APPLIED = 'reisen.setIsAnyFilterApplied';
export const SET_IS_CURRENT_FILTER_APPLIED = 'reisen.setIsCurrentFilterApplied';

export const RESET_REISEN_KUERZEL_FILTER = 'reisen.resetReisenKuerzelFilter';
export const SET_REISEN_KUERZEL_FILTER = 'reisen.setReisenKuerzelFilter';
export const GET_REISEN_KUERZEL_FILTER_OPTIONS = 'reisen.getReisenFilterOptions';
export const SET_REISEN_KUERZEL_FILTER_OPTIONS = 'reisen.setReisenFilterOptions';

export const SET_IS_WITHOUT_KONFIGURATION_FILTER = 'reisen.setReisenIsWithoutKonfigurationFilter';

export const SET_REGION_FILTER = 'reisen.setRegionFilter';
export const RESET_REGION_FILTER = 'reisen.resetRegionFilter';

export const SET_START_DATE_FILTER = 'reisen.setStartDateFilter';
export const RESET_START_DATE_FILTER = 'reisen.resetStartDateFilter';

export const SET_COUNTRY_FILTER = 'reisen.setCountryFilter';
export const RESET_COUNTRY_FILTER = 'reisen.resetCountryFilter';

export const SET_PRIORITAET_FILTER = 'reisen.setPrioritaetFilter';
export const RESET_PRIORITAET_FILTER = 'reisen.resetPrioritaetFilter';

export const SET_REGIONSMANAGER_FILTER = 'reisen.setRegionsmanagerFilter';
export const RESET_REGIONSMANAGER_FILTER = 'reisen.resetRegionsmanagerFilter';

export const ADD_KONFIGURATION = 'reisen.addKonfiguration';
export const UPDATE_KONFIGURATION = 'reisen.updateKonfiguration';
export const DELETE_KONFIGURATION = 'reisen.deleteKonfiguration';

export const GET_REISEN_PRIORITY_DATA = 'reisen.getReisenPriorityData';
export const SET_REISEN_PRIORITY_DATA = 'reisen.setReisenPriorityData';
export const APPLY_REISEN_PRIORITY_DATA = 'reisen.applyReisenPriorityData';

export const SET_REGIONSMANAGER = 'reisen.setRegionsmanager';
export const INITIALIZE_REGIONSMANAGER = 'reisen.initializeRegionsmanager';

export const UPDATE_REISE_WETTBEWERBER_NOTIZEN = 'reisen.updateWettbewerberNotizen';

const state = () => ({
  reiseDetail: null,
  currentAppliedFilterStringified: null,
  seedDateHistory: [],
  reisenPriorityData: null,
  reisen: [],
  countriesGroupedByRegions: null,
  isAddingReise: false,
  top: 100,
  skip: 0,
  orderBy: null,
  count: 0,
  isCurrentFilterApplied: true,
  isAnyFilterApplied: false,
  reisenkuerzelFilterOptions: [],
  regionsmanager: [],
  filter: {
    reisenkuerzelFilter: [],
    // Continent Filter TODO: Rename region to continent
    regionFilter: [],
    // Country Filter
    countryFilter: [],
    // Show reisen with reiseterminen with missing data filter
    prioritaetFilter: [],
    regionsmanagerFilter: [],
    missingDataFilter: null,
    startDateFilter: { start: null, end: null },
    isWithoutKonfigurationFilter: false,
  },
  expand: {
    konfigurationen: {},
    saisonpreise: {},
    fullMetadataHistory: {
      filter: { Reisetermin: null },
      expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} },
    },
    Reisetermine: {
      expand: {
        pax: {},
        gueltigeKonfiguration: {},
        Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
      },
      filter: { isDeleted: false, startdatum: { ge: new Date() } },
    },
  },
  overviewExpand: {
    konfigurationen: {},
    Reisetermine: {
      filter: { isDeleted: false, startdatum: { ge: new Date() } },
      count: true,
      top: 0,
    },
  },
  isBusy: true,
  isInitiallyLoading: true,
});

const initialState = { ...state() };

const getters = {
  getReisekuerzelOptions(state) {
    return state.reisenkuerzelFilterOptions;
  },
  isDifferentFilterApplied(state) {
    return JSON.stringify(state.filter) === state.currentAppliedFilterStringified;
  },
  getRegionsmanagerConfig(state) {
    return state.regionsmanager;
  },
};

const actions = {
  [INITIALIZE_REGIONSMANAGER](context) {
    // filter out maike, weil sie nur eingetragen ist als RM, damit jede Reise einen RM hat
    return apiService
      .get(
        "Reise?$filter=regionsmanager ne '' and regionsmanager ne '61ca51a5-ee49-4bd9-83a4-2e7f7f2ac67b'&$select=regionsmanager, reisekuerzel"
      )
      .then(response => {
        const { value } = response.data.result;
        const regionsmanagerConfig = value.reduce((acc, curr) => {
          const { regionsmanager, reisekuerzel } = curr;
          if (!acc[regionsmanager]) {
            acc[regionsmanager] = [reisekuerzel];
          } else {
            acc[regionsmanager].push(reisekuerzel);
          }
          return acc;
        }, {});
        context.commit(SET_REGIONSMANAGER, regionsmanagerConfig);
      });
  },
  [UPDATE_KONFIGURATION](context, { id: id, konfiguration: konfiguration, reisekuerzel: reisekuerzel }) {
    context.commit(SET_REISEN_IS_BUSY, true);
    return updateKonfiguration(reisekuerzel, konfiguration, id).finally(() => {
      return context.dispatch(GET_REISE_DETAIL, { reisekuerzel: reisekuerzel });
    });
  },
  [DELETE_KONFIGURATION](context, { id: id, reisekuerzel: reisekuerzel }) {
    context.commit(SET_REISEN_IS_BUSY, true);
    return deleteKonfiguration(reisekuerzel, id).finally(() => {
      return context.dispatch(GET_REISE_DETAIL, { reisekuerzel: reisekuerzel });
    });
  },
  [ADD_KONFIGURATION](context, { konfiguration: konfiguration, reisekuerzel: reisekuerzel }) {
    context.commit(SET_REISEN_IS_BUSY, true);
    return addKonfiguration(reisekuerzel, konfiguration).finally(() => {
      return context.dispatch(GET_REISE_DETAIL, { reisekuerzel: reisekuerzel });
    });
  },
  [GET_REISE_DETAIL](context, { reisekuerzel: reisekuerzel }) {
    context.commit(SET_REISEN_IS_BUSY, true);

    return getReise(
      {
        reisekuerzel: reisekuerzel,
      },
      context.state.expand,
      1,
      0,
      null
    ).then(response => {
      context.commit(SET_IS_INITIALLY_LOADING, false);
      context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);

      context.commit(SET_REISE_DETAIL, response.data[0] || {});
      context.commit(SET_REISEN_IS_BUSY, false);
      return response.data[0];
    });
  },
  [SAVED_FILTER](context) {
    context.commit(SET_REISEN_SKIP, 0);
    return context.dispatch(GET_REISEN, { isOverview: true });
  },
  [GET_REISEN](context, { isOverview: isOverview }) {
    const expand = isOverview ? context.state.overviewExpand : context.state.expand;
    context.commit(SET_REISEN_IS_BUSY, true);
    context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_STRINGIFIED_FILTER, JSON.stringify(context.state.filter));
    context.commit(
      SET_IS_ANY_FILTER_APPLIED,
      !isObjectJSONStringifyEqual(context.state.filter, initialState.filter)
    );
    return getReise(
      buildOdataFilterFromFilter(context.state.filter),
      expand,
      context.state.top,
      context.state.skip,
      context.state.orderBy
    ).then(response => {
      context.commit(SET_IS_INITIALLY_LOADING, false);

      context.commit(SET_REISEN, response.data || []);
      context.commit(SET_REISEN_IS_BUSY, false);
      context.commit(SET_REISEN_COUNT, response.count || 0);
    });
  },
  [GET_ALL_COUNTRIES_GROUPED_BY_REGIONS](context) {
    return odataService.getAllCountriesGroupedByRegions().then(countriesGroupedByRegions => {
      return context.commit(SET_ALL_COUNTRIES_GROUPED_BY_REGIONS, countriesGroupedByRegions);
    });
  },
  [REISEN_SORT_CHANGED](context, { sortContext: sortContext }) {
    const orderBy = convertTableSortToOdata(sortContext.sortBy, sortContext.sortDesc);
    context.commit(SET_REISEN_ORDER_BY, orderBy);
    context.commit(SET_REISEN_SKIP, 0);
    return context.dispatch(GET_REISEN, { isOverview: true });
  },
  [REISEN_PAGE_CHANGED](context, { pageContext: pageContext }) {
    context.commit(SET_REISEN_SKIP, pageContext.skip);
    context.commit(SET_REISEN_TOP, pageContext.top);
    return context.dispatch(GET_REISEN, { isOverview: true });
  },
  [UPDATE_REISE](context, { reise: reise }) {
    return updateReise(reise);
    // .then(() => {
    //   return context.dispatch(GET_REISEN, { isOverview: false });
    // });
  },
  [ADD_REISE](context, { reise: reise }) {
    return addReise(reise);
  },
  [RESET_FILTER](context) {
    context.commit(SET_REISEN_SKIP, 0);
    context.commit(RESET_PRIORITAET_FILTER);
    context.commit(RESET_REGIONSMANAGER_FILTER);
    context.commit(SET_IS_ANY_FILTER_APPLIED, true);
    context.commit(SET_IS_CURRENT_FILTER_APPLIED, true);
    context.commit(SET_ALL_FILTER_TO_INTIAL);
    return context.dispatch(GET_REISEN, { isOverview: true });
  },
  [GET_REISEN_KUERZEL_FILTER_OPTIONS](context) {
    return getReiseFilterOptions(null).then(options => {
      return context.commit(SET_REISEN_KUERZEL_FILTER_OPTIONS, options);
    });
  },
  [GET_REISEN_PRIORITY_DATA](context) {
    context.commit(SET_REISEN_IS_BUSY, true);

    return getReisePriorityData()
      .then(data => {
        return context.commit(SET_REISEN_PRIORITY_DATA, data);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        context.commit(SET_REISEN_IS_BUSY, false);
      });
  },
  [APPLY_REISEN_PRIORITY_DATA](context) {
    context.commit(SET_REISEN_IS_BUSY, true);
    return applyReisePriorityData()
      .then(() => {
        return context.dispatch(GET_REISEN_PRIORITY_DATA);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        context.commit(SET_REISEN_IS_BUSY, false);
      });
  },
  [UPDATE_REISE_WETTBEWERBER_NOTIZEN](context, params) {
    context.commit(SET_REISEN_IS_BUSY, true);
    const { wettbewerberNotizen, reisekuerzel } = params;
    return apiService
      .put(`/reise/${reisekuerzel}/updateWettbewerberNotizen?wettbewerberNotizen=${wettbewerberNotizen}`)
      .then(result => result)
      .finally(() => context.commit(SET_REISEN_IS_BUSY, false));
  },
};

const mutations = {
  [SET_REGIONSMANAGER](state, regionsmanager) {
    state.regionsmanager = regionsmanager;
  },
  [SET_STRINGIFIED_FILTER](state, string) {
    state.currentAppliedFilterStringified = string;
  },
  [SET_REISE_DETAIL](state, reise) {
    state.reiseDetail = reise;
  },
  [SET_REISEN](state, reisen) {
    state.reisen = [...reisen];
  },
  [SET_REISEN_COUNT](state, count) {
    state.count = count;
  },
  [SET_REISEN_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_REISEN_ORDER_BY](state, orderBy) {
    state.orderBy = orderBy;
  },
  [SET_REISEN_SKIP](state, skip) {
    state.skip = skip;
  },
  [SET_REISEN_TOP](state, top) {
    state.top = top;
  },
  [SET_ALL_COUNTRIES_GROUPED_BY_REGIONS](state, countriesGroupedByRegions) {
    state.countriesGroupedByRegions = countriesGroupedByRegions;
  },
  [SET_IS_ADDING_REISE](state, isAddingReise) {
    state.isAddingReise = isAddingReise;
  },
  // General mutations
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_ALL_FILTER_TO_INTIAL](state) {
    state.filter.reisenkuerzelFilter = initialState.filter.reisenkuerzelFilter;
    state.filter.countryFilter = initialState.filter.countryFilter;
    state.filter.regionFilter = initialState.filter.regionFilter;
    state.filter.missingDataFilter = initialState.filter.missingDataFilter;
    state.filter.startDateFilter = initialState.filter.startDateFilter;
    state.filter.prioritaetFilter = initialState.filter.prioritaetFilter;
    state.filter.regionsmanagerFilter = initialState.filter.regionsmanagerFilter;
    state.filter.isWithoutKonfigurationFilter = initialState.filter.isWithoutKonfigurationFilter;
  },
  // Filter mutations
  [SET_IS_ANY_FILTER_APPLIED](state, isAnyFilterApplied) {
    state.isAnyFilterApplied = isAnyFilterApplied;
  },
  [SET_IS_CURRENT_FILTER_APPLIED](state, isCurrentFilterApplied) {
    state.isCurrentFilterApplied = isCurrentFilterApplied;
  },
  [SET_REISEN_KUERZEL_FILTER_OPTIONS](state, options) {
    state.reisenkuerzelFilterOptions = options;
  },
  [SET_REISEN_KUERZEL_FILTER](state, filter) {
    state.filter.reisenkuerzelFilter = filter;
  },
  [RESET_REISEN_KUERZEL_FILTER](state) {
    state.filter.reisenkuerzelFilter = [];
  },
  // Region (Continent) Filter
  [SET_REGION_FILTER](state, filter) {
    state.filter.regionFilter = filter;
  },
  [RESET_REGION_FILTER](state) {
    state.filter.regionFilter = [];
  },
  // Country (Continent) Filter
  [SET_COUNTRY_FILTER](state, filter) {
    state.filter.countryFilter = filter;
  },
  [RESET_COUNTRY_FILTER](state) {
    state.filter.countryFilter = [];
  },

  [SET_PRIORITAET_FILTER](state, filter) {
    state.filter.prioritaetFilter = filter;
  },
  [RESET_PRIORITAET_FILTER](state) {
    state.filter.prioritaetFilter = [];
  },

  [SET_REGIONSMANAGER_FILTER](state, filter) {
    state.filter.regionsmanagerFilter = filter;
  },
  [RESET_REGIONSMANAGER_FILTER](state) {
    state.filter.regionsmanagerFilter = [];
  },

  [SET_START_DATE_FILTER](state, startDateFilter) {
    state.filter.startDateFilter = startDateFilter || { start: null, end: null };
  },
  [RESET_START_DATE_FILTER](state) {
    state.filter.startDateFilter = { start: null, end: null };
  },
  [SET_IS_WITHOUT_KONFIGURATION_FILTER](state, isWithoutKonfigurationFilter) {
    state.filter.isWithoutKonfigurationFilter = isWithoutKonfigurationFilter;
  },
  [SET_REISEN_PRIORITY_DATA](state, data) {
    state.reisenPriorityData = data;
  },
};

function buildOdataFilterFromFilter(filterObject) {
  const regionsmanagerFilter = buildQueryElementFromArrayWithProperty(
    filterObject.regionsmanagerFilter.map(shortname => getRegionsmanagerByShortname(shortname)[0]),
    'regionsmanager'
  );

  const prioritaetFilter = buildQueryElementFromArrayWithProperty(
    filterObject.prioritaetFilter,
    'prioritaet'
  );
  const reisenkuerzelFilter = buildReiseQueryElement(filterObject.reisenkuerzelFilter);

  const regionFilter = buildQueryElementFromArrayWithProperty(
    filterObject.regionFilter.map(region => region.value),
    'region'
  );

  const countryFilter = buildQueryElementFromArrayWithProperty(
    filterObject.countryFilter.map(country => country.value),
    'country'
  );
  const { missingDataFilter } = filterObject;

  const startDateFilter = {
    reisetermine: {
      any: buildDateRangeFilterQuery(filterObject.startDateFilter, 'startdatum'),
    },
  };

  const isWithoutKonfigurationFilter = filterObject.isWithoutKonfigurationFilter
    ? {
        reisetermine: {
          any: {
            and: [
              { isDeleted: false },
              { reise: { konfigurationen: { $count: 0 } } },
              { reiseterminState: { baseState: { ne: 'Durchgefuehrt' } } },
              'reisetermine/startdatum gt now()',
            ],
          },
        },
      }
    : null;

  const reisenMitReisetermineInFuture = {
    reisetermine: {
      any: { startdatum: { ge: new Date() } },
    },
  };

  return [
    isWithoutKonfigurationFilter,
    missingDataFilter,
    reisenkuerzelFilter,
    regionFilter,
    countryFilter,
    startDateFilter,
    prioritaetFilter,
    regionsmanagerFilter,
    reisenMitReisetermineInFuture,
  ];
}

function buildReiseQueryElement(reisen) {
  if (Array.isArray(reisen) && reisen.length > 0) {
    return { id: { in: reisen.map(reise => reise.id) || null } };
  } else {
    return null;
  }
}

function getReise(filter, expand, top, skip, orderBy) {
  return odataService
    .getReise({
      filter: filter,
      expand: expand,
      top: top,
      skip: skip,
      orderBy: orderBy,
      count: true,
    })
    .then(response => {
      return response;
    });
}

function updateReise(reiseObject) {
  return apiService
    .update('Reise', reiseObject.id, {
      id: reiseObject.id,
      country: reiseObject.country,
      region: reiseObject.region,
      prioritaet: reiseObject.prioritaet,
      titel: reiseObject.titel,
      regionsmanager: reiseObject.regionsmanager,
      automatischeAufgabeMtzErreicht: reiseObject.automatischeAufgabeMtzErreicht,
      automatischeAufgabeTktl: reiseObject.automatischeAufgabeTktl,
    })
    .then(response => {
      return response;
    });
}

function addReise(reiseObject) {
  return apiService
    .post('Reise', {
      reisekuerzel: reiseObject.reisekuerzel,
      country: reiseObject.country,
      region: reiseObject.region,
      prioritaet: reiseObject.prioritaet,
      titel: reiseObject.titel,
      regionsmanager: reiseObject.regionsmanager,
      automatischeAufgabeMtzErreicht: reiseObject.automatischeAufgabeMtzErreicht,
    })
    .then(response => {
      return response;
    })
    .catch(response => {
      console.error(response);
    });
}

function getReisePriorityData() {
  return apiService
    .put(`Reise/calculated-priorities?apply=false`)
    .then(response => {
      return response?.data?.result?.value;
    })
    .catch(response => {
      console.error(response);
    });
}

function applyReisePriorityData() {
  return apiService
    .put(`Reise/calculated-priorities?apply=true`)
    .then(response => {
      return response?.data?.result?.value;
    })
    .catch(response => {
      console.error(response);
    });
}

function addKonfiguration(reisekuerzel, konfiguration) {
  return apiService.post(`Reise/${reisekuerzel}/konfiguration`, { ...konfiguration });
}

function deleteKonfiguration(reisekuerzel, id) {
  return apiService.delete(`Reise/${reisekuerzel}/konfiguration/${id}`);
}

function updateKonfiguration(reisekuerzel, konfiguration, id) {
  return apiService.put(`Reise/${reisekuerzel}/konfiguration/${id}`, { ...konfiguration });
}

export default {
  state,
  actions,
  mutations,
  getters,
};
